<template>
  <div
    ref="$el"
    class="content-card"
    :class="{
      'featured': props.featured,
      'highlighted': props.highlighted,
      'square': props.content.type && props.content.type.toLowerCase() === 'webinars',
      'display-on-modal': props.displayOnModal,
    }"
    @click.stop="onClick"
  >
    <ClientOnly>
      <div class="head">
        <UiComponentImage
          v-if="props.content.backgroundImage"
          :key="`background-${headWidth}x${headHeight}`"
          :alt="props.content.title"
          class="background"
          :class="{ contains: props.contains }"
          :expected-size="headWidth"
          :extra-params="{
            h: headHeight,
            w: headWidth,
            fit: 'crop',
            crop: props.crop
              ? props.crop
              : props.featured
                ? 'top'
                : 'center',
          }"
          :lazyload="props.lazyload"
          :orientation="headHeight > headWidth ? 'portrait' : 'landscape'"
          :height="headHeight"
          :source="props.content.backgroundImage.url"
          :width="headWidth"
        />
        <MoleculeEbook
          v-if="props.content.image && props.content.image.url && !props.isAVideoTemplate"
          :image="props.content.image"
          :lazyload="props.lazyload"
          :orientation="
            props.content.image.width > props.content.image.height
              ? 'landscape'
              : 'portrait'
          "
          :size="expectedWidthForEbook ? expectedWidthForEbook : null"
        />

        <UiComponentWrapper
          v-if="props.content.videoCardImages && props.content.videoCardImages.length > 0 && props.isAVideoTemplate && expectedWidthForVideos"
          class="videos"
        >
          <UiComponentWrapper
            class="inner"
            :style="{
              aspectRatio: `${Math.max(...props.content.videoCardImages.map(({ width }) => width))} / ${Math.max(...props.content.videoCardImages.map(({ height }) => height))}`,
              transform: `translateY(${16 * (props.content.videoCardImages.length-2)}px)`,
            }"
          >
            <UiComponentWrapper
              v-for="({ alt, height, url, width }, index) in props.content.videoCardImages"
              :key="`resource-${props.content.id}-video-${index}`"
              class="item"
            >
              <UiComponentImage
                :alt="alt || props.content.title"
                class="illustation"
                :height="height"
                :expected-size="expectedWidthForVideos - (32 * index)"
                :source="url"
                :width="width"
              />
              <img
                alt="Icon"
                class="play"
                height="40"
                loading="lazy"
                src="@/assets/svg/video-play.svg"
                width="40"
              >
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </div>
    </ClientOnly>
    <div class="body">
      <MoleculeTagWithImage
        v-if="props.content.tagIcon && props.content.type"
        :color="props.content.tagColor"
        :lazyload="!props.displayOnModal"
        :icon="props.content.tagIcon"
      >
        {{ props.content.type }}
      </MoleculeTagWithImage>
      <UiComponentTag
        v-else-if="props.content.type"
        type="secondary"
      >
        {{
          props.content.type
        }}
      </UiComponentTag>
      <AtomButton
        :action="props.content.event && props.content.event.name"
        :attributes="props.content.event && props.content.event.attributes"
        :force-new-tab="props.forceNewTab"
        :to="props.content.to"
        type="no-style"
      >
        <UiComponentHeading
          v-if="props.content.title"
          :as="props.as"
          :level="2"
        >
          {{
            props.content.title
          }}
        </UiComponentHeading>
      </AtomButton>
      <UiComponentParagraph
        class="description"
        :class="{ empty: !props.content.description }"
        size="s"
      >
        {{ props.content.description }}
      </UiComponentParagraph>
      <AtomButton
        :action="props.content.action"
        size="l"
        type="text"
        :icon-position="props.content.image ? 'icon-left' : 'icon-right'"
      >
        {{ props.content.callToAction }}
        <UiComponentIcon :type="props.content.image ? 'download' : 'arrow-right'" />
      </AtomButton>
    </div>
  </div>
</template>

<script setup>
// Composables
const { width: screenWidth } = useScreenSize()

// Props
const props = defineProps({
  as: {
    default() {
      return 'heading'
    },
    required: false,
    type: String,
    validator(value) {
      return ['heading', 'paragraph'].indexOf(value) !== -1
    },
  },
  contains: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  content: {
    required: true,
    type: Object,
  },
  crop: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  displayAsArticle: {
    default() {
      return false
    },
    require: false,
    type: Boolean,
  },
  displayOnModal: {
    default() {
      return false
    },
    require: false,
    type: Boolean,
  },
  featured: {
    default() {
      return false
    },
    require: false,
    type: Boolean,
  },
  forceNewTab: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  highlighted: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  isAVideoTemplate: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  lazyload: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
})

// Refs
const $el = ref(null)
const headHeight = ref(343)
const headWidth = ref(328)
const resizeObserver = ref(null)

// Computed Properties
const attributes = computed(() => {
  return props.content.to.includes('http')
    ? {
        href: props.content.to,
        target: props.content.image ? '_blank' : '',
      }
    : {
        to: props.content.to,
      }
})

const expectedWidthForEbook = computed(() => {
  if (props.displayAsArticle) {
    return 182 * (props.content?.image?.width / props.content?.image?.height)
  }

  if (screenWidth.value >= 1024 && props.displayOnModal) {
    return 182 * (props.content?.image?.width / props.content?.image?.height)
  }

  if (screenWidth.value >= 1328 && props.featured) {
    return 160 * (props.content?.image?.width / props.content?.image?.height)
  }

  return null
})

const expectedWidthForVideos = computed(() => {
  return headWidth.value - 32
})

const is = computed(() => {
  return props.content.to.includes('http') ? 'a' : 'NuxtLink'
})

// Methods
function onClick() {
  $el.value.querySelector('.button').click()
}

function onObserve() {
  if ($el.value.querySelector('.head')) {
    headHeight.value = $el.value.querySelector('.head').offsetHeight
    headWidth.value = $el.value.querySelector('.head').offsetWidth
  }
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  resizeObserver.value?.disconnect()
})

onMounted(() => {
  resizeObserver.value = new ResizeObserver(onObserve)
  resizeObserver.value.observe($el.value)
})
</script>

<style lang="postcss" scoped>
.content-card {
  @apply flex flex-col w-full h-auto bg-white border-2 border-grey-blue-200 rounded-4xl cursor-pointer overflow-hidden transition-all ease-out duration-500;

  @screen md {
    @apply flex-row;
    height: 343px;
  }

  @screen lg {
    height: 290px;
  }

  &:hover {
    @apply shadow-light4;

    .button.text {
      @apply text-livestorm-blue-700 !important;
    }
  }

  &.featured {
    @apply h-auto border-0 shadow-light2 transition-shadow ease-in-out duration-300;

    @screen xl {
      @apply flex-col justify-start;
    }

    &:hover {
      @apply shadow-light4;

      .head {
        .ebook {
          @apply transform -translate-x-1/2 -translate-y-1/2 scale-102;
        }
      }
    }

    .head {
      @screen md {
        @apply items-stretch w-85;
      }

      @screen xl {
        @apply w-full h-52;
      }

      .background {
        :deep(img) {
          @apply object-top;
        }
      }

      .ebook {
        @apply transform -translate-x-1/2 -translate-y-1/2 scale-100 transition-transform ease-in-out duration-500;

        &.landscape {
          @screen xl {
            @apply h-40 w-auto !important;
          }
        }
      }
    }

    .body {
      @screen xl {
        @apply p-8;
      }

      .tag,
      .tag-with-image {
        @screen xl {
          @apply h-6 px-2 py-1 font-medium text-xs leading-4 rounded-xs;
        }

        :deep(picture) {
          @screen xl {
            @apply w-3 h-3 mr-1 !important;
          }
        }
      }

      .button.no-style + .description {
        @screen xl {
          @apply mt-2;
        }
      }
    }
  }

  &.highlighted {
    @apply border-0 shadow-light4;

    @screen md {
      @apply flex-row h-85;
    }

    @screen lg {
      max-height: 340px;
    }

    &.square {
      @screen lg {
        @apply h-108;
      }

      .head {
        @screen lg {
          @apply w-full h-full;
        }

        .picture {
          @screen lg {
            @apply w-full h-full;
          }
        }
      }
    }

    .head {
      @apply w-full;
      height: 343px;

      @screen md {
        @apply w-full max-w-85;
      }

      @screen lg {
        max-width: 510px;
      }

      .ebook {
        &.landscape {
          @apply h-auto;
          width: calc(100% - 50px);

          @screen md {
            width: calc(100% - 64px);
          }

          @screen lg {
            width: calc(100% - 132px);
          }
        }
      }
    }

    .body {
      @apply p-6;

      @screen lg {
        @apply justify-center px-20 !important;
      }

      .h2 {
        @apply text-2xl leading-8 !important;
      }
    }
  }

  .head {
    @apply relative w-full h-auto overflow-hidden bg-grey-blue-200;
    height: 343px;

    @screen md {
      @apply flex-shrink-0;
      width: 343px;
    }

    @screen lg {
      height: 290px;
      width: 290px;
    }

    .background {
      @apply rounded-none w-full;

      &.contains {
        :deep(img) {
          @screen lg {
            @apply object-contain object-top;
          }
        }
      }
    }

    .ebook {
      @apply absolute left-1/2 top-1/2 -mt-1 transform -translate-x-1/2 -translate-y-1/2;

      &.landscape {
        @apply h-auto;
        width: calc(100% - 50px);

        @screen md {
          width: calc(100% - 64px);
        }
      }

      &.portrait {
        width: 60%;
      }
    }

    .videos {
      @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
      width: calc(100% - 32px);

      .inner {
        @apply relative h-full w-full;

        .item {
          @apply absolute transform;

          &:nth-child(1) {
            @apply z-20;
          }

          &:nth-child(2) {
            @apply translate-x-4 -translate-y-4 z-10;
          }

          &:nth-child(3) {
            @apply translate-x-8 -translate-y-8 z-0;
          }

          .illustation {
            @apply rounded-none;
          }
        }

        .play {
          @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10;
        }
      }
    }
  }

  .body {
    @apply flex flex-col justify-start w-full p-6;

    @screen md {
      @apply justify-center;
    }

    @screen lg {
      @apply px-10;
    }

    .tag,
    .tag-with-image {
      @apply self-start text-sm font-bold;
    }

    .tag + .button.no-style,
    .tag-with-image + .button.no-style {
      @apply mt-4;
    }

    .button.no-style {
      @apply self-start;

      .h2 {
        @apply mt-0 font-medium text-winter-green-900 text-left text-origin leading-7 !important;

        @screen md {
          @apply text-xl leading-7 !important;
        }
      }
    }

    .description {
      @apply mt-4 text-grey-blue-600 text-sm leading-6 normal-case whitespace-normal overflow-hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding-bottom: 1px;

      &.empty {
        @apply hidden !important;
      }
    }

    .button.text {
      @apply h-auto self-start mt-4 text-livestorm-blue-700 px-0;

      i {
        @apply text-livestorm-blue-700;
      }
    }
  }

  &.display-on-modal {
    @screen lg {
      @apply flex-col h-auto;
    }

    &:hover {
      @apply shadow-none border-grey-blue-200;
    }

    .body {
      @screen lg {
        @apply pt-8 px-6 pb-6;
      }

      .button.text {
        :deep(i) {
          @apply text-black !important;
        }
      }
    }

    &.resources {
      .head {
        @screen lg {
          height: 246px;
        }
      }

      .button.text {
        :deep(i) {
          @apply text-white !important;
        }
      }
    }
  }
}
</style>
